.nav-second-level .nav-second-level {
    margin-left: 30px;
}

.label-nen-1 {
    color: #000;
    background: #43b32e;
    font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.label-nen-2 {
    color: #000;
    background: #ffe22e;
    font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.label-nen-3 {
    color: #000;
    background: #fd3c3c;
    font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.daterangepicker {
     margin-top: auto;
     top: auto;
     left: auto;
}

@media (min-width: 992px) {
    #modal-issue-reaction-form .modal-dialog.modal-lg {
        width: 900px !important;
    }
}
